<template>
  <b-overlay :show="show" opacity="0.5" rounded="sm">
    <div>
      <form-wizard
        color="#3C4A41"
        :title="null"
        :subtitle="null"
        :finish-button-text="$t('Submit')"
        :back-button-text="$t('Previous')"
        :next-button-text="$t('Next')"
        class="steps-transparent mb-3"
        @on-complete="
          show = !show;
          onSubmit();
        "
        @on-error="handleErrorMessage"
        @on-loading="setLoading"
      >
        <!-- customer tab -->
        <tab-content
          :title="$t('Personal Information')"
          ref="personalInfoTabForm"
          :before-change="validatePersonalInfoForm"
          icon="feather icon-user"
        >
          <validation-observer ref="personalInfoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{$t('Personal Information')}}</h5>
              </b-col>
              <b-col md="6">
                <!-- Vorname -->
                <validation-provider
                  #default="validationContext"
                  name="Vorname"
                  rules="required"
                >
                  <b-form-group :label="$t('First Name')" label-for="vorname">
                    <b-form-input
                      id="vorname"
                      v-model="customerLocal.vorname"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Nachname -->
                <validation-provider
                  #default="validationContext"
                  name="Nachname"
                  rules="required"
                >
                  <b-form-group :label="$t('Last Name')" label-for="nachname">
                    <b-form-input
                      id="nachname"
                      v-model="customerLocal.nachname"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Nachname -->
                <!-- Geburtsdatum -->
                <validation-provider
                  #default="validationContext"
                  name="Geburtsdatum"
                  rules="required"
                >
                  <b-form-group :label="$t('Date of Birth')" label-for="geburtsdatum">
                    <datepicker
                      name="geburtsdatum"
                      :format="'dd/MM/yyyy'"
                      v-model="customerLocal.geburtsdatum"
                      :input-class="'form-control'"
                      :class="{
                        'is-invalid': validationContext.errors.length > 0,
                        'is-valid':
                          getValidationState(validationContext) == true,
                      }"
                    ></datepicker>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Gender -->
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Gender"
                  rules="required"
                >
                  <b-form-group :label="$t('Gender')" label-for="Gender">
                    <b-form-radio-group
                      id="gender"
                      v-model="customerLocal.gender"
                      name="gender"
                      :options="genders"
                      class="inline-spacing"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Email -->
                <validation-provider
                  #default="validationContext"
                  name="Emailadresse"
                  rules="required|email"
                >
                  <b-form-group :label="$t('Email Address')" label-for="emailadresse">
                    <b-form-input
                      id="emailadresse"
                      v-model="customerLocal.emailadresse"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- Telefonnummer -->
                <validation-provider
                  #default="validationContext"
                  name="Telefonnummer"
                  rules="required"
                >
                  <b-form-group :label="$t('Phone Number')" label-for="telefonnummer">
                    <b-form-input
                      id="telefonnummer"
                      v-model="customerLocal.telefonnummer"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <br />
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t('Address') }}</h5>
              </b-col>
              <b-col md="6">
                <!-- Strassename -->
                <validation-provider
                  #default="validationContext"
                  name="Strassename"
                  rules="required"
                >
                  <b-form-group :label="$t('Street Name')" label-for="strassename">
                    <b-form-input
                      id="strassename"
                      v-model="customerLocal.adresse.strassename"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- Strassenummer -->
                <validation-provider
                  #default="validationContext"
                  name="Strassenummer"
                  rules="required"
                >
                  <b-form-group :label="$t('Street Number')" label-for="strassenummer">
                    <b-form-input
                      id="strassenummer"
                      v-model="customerLocal.adresse.strassenummer"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- Postleitzahl -->
                <validation-provider
                  #default="validationContext"
                  name="Postleitzahl"
                  rules="required"
                >
                  <b-form-group :label="$t('Postal Code')" label-for="postleitzahl">
                    <vue-autosuggest
                      v-model="customerLocal.adresse.postleitzahl"
                      :suggestions="plzListFiltered"
                      :limit="10"
                      :input-props="{
                        id: 'postleitzahl',
                        class: 'form-control',
                      }"
                      @input="onInputChange"
                      @selected="(suggestion) => { customerLocal.adresse.postleitzahl = suggestion.item}"
                      :get-suggestion-value="getSuggestionValue"
                      :state="getValidationState(validationContext)"
                      trim
                    >
                      <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">{{
                          suggestion.item
                        }}</span>
                      </template>
                    </vue-autosuggest>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- Ort -->
                <validation-provider
                  #default="validationContext"
                  name="Ort"
                  rules="required"
                >
                  <b-form-group :label="$t('Location')" label-for="ort">
                     <!--<b-form-input
                      id="ort"
                      v-model="customerLocal.adresse.ort"
                      :state="getValidationState(validationContext)"
                      trim
                    /> -->

                    <!-- <v-select
                      v-model="customerLocal.adresse.ort"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :filter="fuseOrtSearch"
                      :options="ortList"
                      :reduce="(val) => val.ort"
                      label="ort"
                      :clearable="false"
                      input-id="ort"
                      id="ort"
                    /> -->

                    <vue-autosuggest
                      v-model="customerLocal.adresse.ort"
                      :suggestions="ortListFiltered"
                      :limit="10"
                      :input-props="{
                        id: 'ort',
                        class: 'form-control',
                      }"
                      @input="onOrtInputChange"
                      @focus="focusOrt"
                      @selected="(suggestion) => { customerLocal.adresse.ort = suggestion.item.name}"
                      :get-suggestion-value="getSuggestionValue"
                    >
                      <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">{{
                          suggestion.item.name
                        }}</span>
                      </template>
                    </vue-autosuggest>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- Kanton -->
                <validation-provider
                  #default="validationContext"
                  name="Kanton"
                  rules="required"
                >
                  <b-form-group :label="$t('Canton')" label-for="kanton">
                    <!-- <b-form-input
                      id="kanton"
                      v-model="customerLocal.adresse.kanton"
                      :state="getValidationState(validationContext)"
                      trim
                    /> -->

                    <!-- <v-select
                      v-model="customerLocal.adresse.kanton"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :filter="fuseKantonSearch"
                      :options="kantonList"
                      :reduce="(val) => val.kanton"
                      label="Kanton"
                      :clearable="false"
                      input-id="kanton"
                      id="kanton"
                    /> -->

                    <vue-autosuggest
                      v-model="customerLocal.adresse.kanton"
                      :suggestions="kantonListFiltered"
                      :limit="10"
                      :input-props="{
                        id: 'ort',
                        class: 'form-control',
                      }"
                      @input="onKantonInputChange"
                      @focus="focusKanton()"
                      @selected="(suggestion) => { customerLocal.adresse.kanton = suggestion.item.name}"
                      :get-suggestion-value="getSuggestionValue"
                    >
                      <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">{{
                          suggestion.item.name
                        }}</span>
                      </template>
                    </vue-autosuggest>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0"></h5>
              </b-col>

              <b-col md="12">
                <b-row>
                  <b-col md="12" sm="12">
                    <!-- GDPR -->
                    <validation-provider
                      #default="validationContext"
                      name="GDPR"
                      :rules="{ required: { allowFalse: false } }"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          id="gdpr"
                          :checked="false"
                          :state="getValidationState(validationContext)"
                          v-model="gdpr"
                        >
                          {{
                            $t(
                              "I accept ASC's privacy policy and understand that my information will be used to provide a quality service."
                            )
                          }}
                        </b-form-checkbox>

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12" sm="12">
                    <validation-provider
                      #default="validationContext"
                      name="Marketingvereinbarung"
                      :rules="{ required: { allowFalse: false } }"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          id="marketingvereinbarung"
                          :checked="false"
                          :state="getValidationState(validationContext)"
                          v-model="marketingvereinbarung"
                        >
                          {{
                            $t(
                              "I agree that my data will be used to inform me about promotions and for marketing purposes. No data will be sold to third party companies."
                            )
                          }}
                        </b-form-checkbox>

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Anamnese -->
        <tab-content
          title="Anamnese"
          :before-change="validateAnamneseForm"
          icon="feather icon-check"
        >
          <validation-observer ref="anamneseRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Anamnese</h5>
              </b-col>
              <b-col cols="12" class="mb-2 business-card">
                <div class="business-items">
                  <div
                    v-for="(q, index) in questions"
                    :key="q.key"
                    class="business-item"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Kanton"
                        rules="required"
                      >
                        <b-form-group>
                          <p>{{ q.option }}</p>
                          <b-form-radio-group
                            v-model="customerLocal.anamnese.answers[index]"
                            :name="'q[' + index + ']'"
                            :options="questionAnswers"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" class="mb-2">
                <validation-provider
                  #default="validationContext"
                  name="ConfirmationAnswer"
                  :rules="{ required: { allowFalse: false } }"
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="confirmAnswer"
                      :checked="false"
                      :state="getValidationState(validationContext)"
                      v-model="confirmAnswer"
                    >
                      {{
                        $t(
                          "I confirm the accuracy of the data submitted for my medical history and agree to submit any changes to ASC no later than before my next appointment."
                        )
                      }}
                    </b-form-checkbox>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t('Signature') }}</h5>
              </b-col>
              <b-col cols="12" class="mb-2">
                <div class="esign">
                  <div class="esign-box">
                    <vue-esign
                      class="esignature"
                      ref="unterschriftEsign"
                      :width="500"
                      :height="200"
                      :isCrop="isCrop"
                      :lineWidth="lineWidth"
                      :lineColor="lineColor"
                      :bgColor.sync="bgColor"
                    />
                  </div>
                  <validation-provider
                    #default="validationContext"
                    name="Unterschrift"
                    rules="required"
                  >
                    <b-form-group>
                      <b-form-input
                        id="unterschrift"
                        ref="unterschrift"
                        v-model="customerLocal.anamnese.unterschrift"
                        :state="getValidationState(validationContext)"
                        style="display: none"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div>
                  <b-button
                    @click="handleResetUnterschrift"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    >{{ $t('Delete') }}</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Kundenvereinbarung -->
        <tab-content
          :title="$t('Customer Agreement')"
          :before-change="validateKundenvereinbarungForm"
          icon="feather icon-file-text"
        >
          <validation-observer ref="kundenvereinbarungRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Kundenvereinbarung</h5>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" class="mb-2">
                <ul>
                  <li>
                    Mit der Unterschrift bestätigt der Kunde, dass er über Art
                    und Umfang der Behandlung im Studio aufgeklärt worden ist
                    und alle vom Fachpersonal erteilten Hinweise verstanden hat.
                    Über das erforderliche Verhalten sowie den notwendigen
                    Sonnenschutz vor und nach einer Behandlung wurde der Kunde
                    ebenso unterrichtet, wie auf die Möglichkeit von
                    Komplikationen und Risiken in der Zeit nach der Behandlung.
                  </li>
                  <li>
                    Insbesondere ist der Kunde darauf hingewiesen worden, dass
                    selbst bei einer fachgerechten Durchführung der Behandlung
                    gewisse Restrisiken bestehen bleiben, die in seltenen Fällen
                    zu folgenden Komplikationen führen können:
                    <ul>
                      <li>
                         Augenschädigungen bei nicht sachgemässer oder
                        unzureichender Abschirmung der Augen. Das Fachpersonal
                        des Studios händigt vor der Behandlung eine geeignete
                        Schutzbrille aus, die während der ganzen Behandlung
                        getragen werden muss
                      </li>
                      <li>Hautrötungen</li>
                      <li>
                        Pigmentverschiebungen, insbesondere bei Hyper-und/oder
                        Hypopigmentierungen
                      </li>
                    </ul>
                  </li>
                  <li>
                    Es ist dem Kunden erklärt worden, dass der Effekt der
                    Anwendung von verschiedenen biologischen und technischen
                    Faktoren abhängt und deshalb zum Teil nicht eine
                    vollständige Entfernung der Hautveränderung erzielt werden
                    kann.
                  </li>
                  <li>
                    Die Häufigkeit der Anwendungen, um die Hautveränderungen zu
                    entfernen, hängt von mehreren Faktoren ab:
                    <ul>
                      <li>Tiefe und Dichte der Haare/Farbstoffe (Tattoo)</li>
                      <li>Ausdehnung der Hautveränderung</li>
                      <li>
                        der körpereignenen Fähigkeit, auf die entstandene
                        Reaktion im Anwendungsgebiet zu reagieren
                      </li>
                    </ul>
                  </li>
                  <li>
                    Für die bevorstehende Behandlung gelten die Preise gemäss
                    der offiziellen Preisliste des Studios.
                  </li>
                </ul>
                <hr class="dotted" />
              </b-col>

              <b-col cols="12" class="mb-2">
                Der Kunde / die Kundin akzeptiert den Haftungsausschluss für die
                Behandlung und für evtl. Folgeschäden wie u.a.:
                <ul>
                  <li>Pigment-/ Farbrückstände, Farbumschlag</li>
                  <li>
                    Ev. keine vollständige Entfernung (Tattoo, Pigmentflecken)
                  </li>
                  <li>
                    Vorübergehende rötliche /bräunliche Kreise am Haarausgang
                    (Papillenschwellung)
                  </li>
                  <li>Narben /Entzündung</li>
                </ul>
              </b-col>

              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Visum</h5>
              </b-col>
              <b-col cols="12" class="mb-2">
                <div class="esign">
                  <div class="esign-box">
                    <vue-esign
                      class="esignature"
                      ref="visum1Esign"
                      :width="500"
                      :height="200"
                      :isCrop="isCrop"
                      :lineWidth="lineWidth"
                      :lineColor="lineColor"
                      :bgColor.sync="bgColor"
                    />
                  </div>
                </div>
                <validation-provider
                  #default="validationContext"
                  name="visum1"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-input
                      id="visum1"
                      v-model="customerLocal.kundenvereinbarung.visum_1"
                      :state="getValidationState(validationContext)"
                      trim
                      style="display: none"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <div>
                  <b-button
                    @click="handleResetVisum1"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    >{{ $t('Delete') }}</b-button
                  >
                </div>
              </b-col>

              <b-col cols="12" class="mb-2">
                Der Kunde / die Kundin erklärt ausdrücklich, dass er/sie
                <ul>
                  <li>
                    Keine lichtsensibilisierenden Mittel eingenommen hat bzw.
                    vor den künftigen Behandlungen einnehmen wird
                  </li>
                  <li>
                    Keine starken Durchblutungsstörungen oder Venenleiden
                    vorliegen
                  </li>
                  <li>Keine Schwangerschaft vorliegt</li>
                  <li>
                    Keine chronischen gesundheitlichen Störungen vorliegen wie:
                    Bluterkrankheit, Kreislaufstörung, Immunsuppression, erhöhte
                    Lichtempfindlichkeit
                  </li>
                  <li>
                    Keine Chemo-oder Radiotherapie, keine hochdosierte
                    Kortisontherapie oder immunsupprimierende Therapie
                    durchgeführt wurde in den letzten 6 Monaten
                  </li>
                  <li>
                    Keine Abhängigkeit von Drogen oder Psychopharmaka besteht
                  </li>
                  <li>
                    Keine Silikoneinlagen oder Hyaluronsäure-Unterspritzungen
                    vorliegen im geplanten Laserbereich
                  </li>
                  <li>
                    Bei Ungewissheit oder Unsicherheiten eine ärztliche
                    Empfehlung vorliegt
                  </li>
                </ul>
              </b-col>

              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Visum</h5>
              </b-col>
              <b-col cols="12" class="mb-2">
                <div class="esign">
                  <div class="esign-box">
                    <vue-esign
                      class="esignature"
                      ref="visum2Esign"
                      :width="500"
                      :height="200"
                      :isCrop="isCrop"
                      :lineWidth="lineWidth"
                      :lineColor="lineColor"
                      :bgColor.sync="bgColor"
                    />
                  </div>
                  <validation-provider
                    #default="validationContext"
                    name="visum2"
                    rules="required"
                  >
                    <b-form-group>
                      <b-form-input
                        id="visum2"
                        v-model="customerLocal.kundenvereinbarung.visum_2"
                        :state="getValidationState(validationContext)"
                        trim
                        style="display: none"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div>
                  <b-button
                    @click="handleResetVisum2"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    >{{ $t('Delete') }}</b-button
                  >
                </div>
              </b-col>

              <b-col cols="12" class="mb-2">
                <p>
                  Für den Fall, dass die vom Kunden gemachten Angaben nicht der
                  Wahrheit entsprechen, verzichtet dieser auf jegliche
                  Schadenersatzansprüche gegenüber dem Studio. Dieser Ausschluss
                  gilt nicht für den Fall, bei dem die Schädigung des Kunden
                  durch eine fahrlässige oder vorsätzliche Fehlmanipulation
                  zugefügt wurde. Langzeitauswirkungen >15 Jahre sind mit dieser
                  Methode nicht bekannt, das Studio übernimmt keine Haftung
                  diesbezüglich.
                </p>
                <p>
                  Ich bestätige mit meiner Unterschrift, dass ich die
                  obenstehende Vereinbarung gelesen und verstanden habe und
                  stimme der Anwendung der Laserbehandlung zu.
                </p>
              </b-col>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t('Signature') }}</h5>
              </b-col>
              <b-col cols="12" class="mb-2">
                <div class="esign">
                  <div class="esign-box">
                    <vue-esign
                      class="esignature"
                      ref="visum3Esign"
                      :width="500"
                      :height="200"
                      :isCrop="isCrop"
                      :lineWidth="lineWidth"
                      :lineColor="lineColor"
                      :bgColor.sync="bgColor"
                    />
                  </div>
                  <validation-provider
                    #default="validationContext"
                    name="visum3"
                    rules="required"
                  >
                    <b-form-group>
                      <b-form-input
                        id="visum3"
                        v-model="customerLocal.kundenvereinbarung.visum_3"
                        :state="getValidationState(validationContext)"
                        trim
                        style="display: none"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div>
                  <b-button
                    @click="handleResetVisum3"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    >{{ $t('Delete') }}</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </div>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vueEsign from "vue-esign";
import Ripple from "vue-ripple-directive";
import i18n from "@/libs/i18n";
import {
  toRefs,
  ref,
  watch,
  onUnmounted,
  onMounted,
} from "@vue/composition-api";
import useCustomerHandler from "../useCustomerHandler";
import flatPickr from "vue-flatpickr-component";
import Datepicker from "vuejs-datepicker";
import store from "@/store";
import customerStoreModule from "../customerStoreModule";
import _ from "lodash";
import Fuse from "fuse.js";
import { VueAutosuggest } from 'vue-autosuggest'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BOverlay,
  
} from "bootstrap-vue";
import { codeIconInfo } from "./code";

export default {
  directives: {
    Ripple,
  },
  components: {
    VueAutosuggest,
    vueEsign,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BCard,
    BCardText,
    BCardTitle,
    BFormRadioGroup,
    flatPickr,
    Datepicker,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormInvalidFeedback,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    var self = this;

    store
      .dispatch("app-customer/fetchAddresses")
      .then((response) => {
        self.plzList = response.data.data.plzList;
        self.ortList = response.data.data.ortList;
        self.kantonList = response.data.data.kantonList;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          self.plzList = [];
          self.ortList = [];
          self.kantonList = [];
        }
      });

    this.$watch(
      () => {
        return this.$refs.unterschriftEsign.points;
      },
      (val) => {
        if (val.length > 0) {
          this.$refs.unterschriftEsign.generate().then((res) => {
            this.customerLocal.anamnese.unterschrift = res;
          });
        }
      }
    );

    this.$watch(
      () => {
        return this.$refs.visum1Esign.points;
      },
      (val) => {
        if (val.length > 0) {
          this.$refs.visum1Esign.generate().then((res) => {
            this.customerLocal.kundenvereinbarung.visum_1 = res;
          });
        }
      }
    );

    this.$watch(
      () => {
        return this.$refs.visum2Esign.points;
      },
      (val) => {
        if (val.length > 0) {
          this.$refs.visum2Esign.generate().then((res) => {
            this.customerLocal.kundenvereinbarung.visum_2 = res;
          });
        }
      }
    );

    this.$watch(
      () => {
        return this.$refs.visum3Esign.points;
      },
      (val) => {
        if (val.length > 0) {
          this.$refs.visum3Esign.generate().then((res) => {
            this.customerLocal.kundenvereinbarung.visum_3 = res;
          });
        }
      }
    );
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    clearCustomerData: {
      type: Function,
      required: true,
    },
    isSignAgain: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      plzList: [],
      ortList: [],
      kantonList: [],
      plzListFiltered: [],
      ortListFiltered: [],
      kantonListFiltered: [],
      show: false,
      locale: "de",
      errorMsg: null,
      required,
      alphaNum,
      email,
      lineWidth: 3,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      selectedContry: "select_value",
      selectedLanguage: "nothing_selected",
      codeIconInfo,
      gdpr: false,
      marketingvereinbarung: false,
      confirmAnswer: false,
      genders: [
        {
          text: this.$i18n.t('Mister'),
          value: "Herr"
        },
        {
          text: this.$i18n.t('Woman'),
          value: "Frau"
        }],
      questions: [
        {
          key: "Q1",
          option: "Nehmen Sie Medikamente ein?",
          checked: null,
        },
        {
          key: "Q2",
          option: "Leiden Sie an einer Herz/Kreislauf-Erkrankung?",
          checked: null,
        },
        {
          key: "Q3",
          option: "Tragen Sie einen Herzschrittmacher / Defibrillator?",
          checked: null,
        },
        {
          key: "Q4",
          option:
            "Leiden Sie an einer Gerinnungsstörung (Nasenbluten, Blutergüssse)?",
          checked: null,
        },
        {
          key: "Q5",
          option: "Leiden Sie an Durchblutungsstörungen / Venenleiden?",
          checked: null,
        },
        {
          key: "Q6",
          option: "Leiden Sie an Allergien / Unverträglichkeiten?",
          checked: null,
        },
        {
          key: "Q7",
          option: "Leiden Sie unter Neurodermitis?",
          checked: null,
        },
        {
          key: "Q8",
          option: "Haben Sie eine Hypo-oder Hyperpigmentierung?",
          checked: null,
        },
        {
          key: "Q9",
          option: "Haben Sie gehäufte Infektionen (Herpes,...)?",
          checked: null,
        },
        {
          key: "Q10",
          option: "Leiden Sie unter Lichtempfindlichkeit?",
          checked: null,
        },
        {
          key: "Q11",
          option: "Neigen Sie zu Narbenwucherung?",
          checked: null,
        },
        {
          key: "Q12",
          option: "Haben Sie Diabetes?",
          checked: null,
        },
        {
          key: "Q13",
          option: "Rauchen Sie?",
          checked: null,
        },
        {
          key: "Q14",
          option:
            "Waren Sie in den letzten 2 Wochen im Solarium oder stärkerer UV-Strahlung ausgesetzt?",
          checked: null,
        },
        {
          key: "Q15",
          option:
            "Haben Sie in den letzten 2 Wochen Selbstbräurter verwendet oder haben Sie ein Peeling durchgeführt (Mikrodermabrasio, Fruchtsäure)?",
          checked: null,
        },
        {
          key: "Q16",
          option:
            "Haben Sie in den letzten 2 Wochen Sonnencrème mit Nanopartikeln angewendet?",
          checked: null,
        },
        {
          key: "Q17",
          option: "Könnten Sie schwanger sein?",
          checked: null,
        },
      ],
      questionAnswers: [
        { text: "Ja", value: true },
        { text: "Nein", value: false },
      ],
    };
  },
  setup(props, { emit }) {
    const CUSTOMER_STORE_MODULE_NAME = "app-customer";

    // Register module
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule);

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_STORE_MODULE_NAME);
    });

    const { customerLocal, resetCustomerLocal, onSubmit } = useCustomerHandler(
      toRefs(props),
      emit
    );

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetCustomerLocal, props.clearCustomerData);

    return {
      customerLocal,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    };
  },
  methods: {
    getSuggestionValue(suggestion) {
      return suggestion.item.name || suggestion.item ;
    },
    onInputChange(search) {
      
      if (search === '' || search === undefined) {
        return
      }

      const plzFuse = new Fuse(this.plzList, {
        shouldSort: true,
      });

      var filteredData = search.length
        ? plzFuse.search(search).map(({ item }) => item)
        : plzFuse.list;

      this.plzListFiltered = [{
        data: filteredData,
      }]
    },
    focusOrt(e) {
      if (this.customerLocal.adresse.ort == "")
      {
        this.onOrtInputChange("");
      }
    },
    onOrtInputChange(search) {
      var vm = this;

      // if (search === '' || search === undefined) {
      //   return
      // }

      var filter = _.filter(this.ortList, function (o) {
        return o.plz == vm.customerLocal.adresse.postleitzahl;
      });

      const ortFuse = new Fuse(filter, {
        keys: ["name"],
        shouldSort: true,
      });

      var filteredData = search.length
        ? ortFuse.search(search).map(({ item }) => item)
        : filter;

      this.ortListFiltered = [{
        data: filteredData,
      }]
    },
    focusKanton(e) {
      if (this.customerLocal.adresse.kanton == "")
      {
        this.onKantonInputChange("");
      }
    },
    onKantonInputChange(search) {
      var vm = this;

      // if (search === '' || search === undefined) {
      //   return
      // }

      var filter = _.filter(this.kantonList, function (o) {
        return o.plz == vm.customerLocal.adresse.postleitzahl;
      });

      const kantonFuse = new Fuse(filter, {
        keys: ["name"],
        shouldSort: true,
      });

      var filteredData = search.length
        ? kantonFuse.search(search).map(({ item }) => item)
        : filter;

      this.kantonListFiltered = [{
        data: filteredData,
      }]
    },
    fusePlzSearch(options, search) {
      var vm = this;
      var filter = _.filter(options, function (o) {
        return true;
      });
      const plzFuse = new Fuse(filter, {
        shouldSort: true,
      });
      return search.length
        ? plzFuse.search(search).map(({ item }) => item)
        : filter;
    },
    
    fuseOrtSearch(options, search) {
      var vm = this;
      var filter = _.filter(options, function (o) {
        return true;
      });
      const ortFuse = new Fuse(filter, {
        shouldSort: true,
      });
      return search.length
        ? ortFuse.search(search).map(({ item }) => item)
        : ortFuse.list;
    },
    fuseKantonSearch(options, search) {
      var vm = this;
      var filter = _.filter(options, function (o) {
        return true;
      });
      const kantonFuse = new Fuse(filter, {
        shouldSort: true,
      });
      return search.length
        ? kantonFuse.search(search).map(({ item }) => item)
        : kantonFuse.list;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("Form Submitted"),
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    setLoading() {
      window.scrollTo(0, 0);
      // this.$refs.unterschrift.generate().then((res) => {
      //   //this.refs.unterschriftInput.value = res;
      //   this.customerLocal.unterschrift = res;
      // });
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },
    validatePersonalInfoForm() {
      return new Promise((resolve, reject) => {
        this.$refs.personalInfoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validateAnamneseForm() {
      return new Promise((resolve, reject) => {
        this.$refs.anamneseRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validateKundenvereinbarungForm() {
      return new Promise((resolve, reject) => {
        this.$refs.kundenvereinbarungRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    handleResetUnterschrift() {
      this.$refs.unterschriftEsign.reset();
      this.customerLocal.anamnese.unterschrift = "";
    },
    handleResetVisum1() {
      this.$refs.visum1Esign.reset();
      this.customerLocal.kundenvereinbarung.visum_1 = "";
    },
    handleResetVisum2() {
      this.$refs.visum2Esign.reset();
      this.customerLocal.kundenvereinbarung.visum_2 = "";
    },
    handleResetVisum3() {
      this.$refs.visum3Esign.reset();
      this.customerLocal.kundenvereinbarung.visum_3 = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-top: 0.5rem;
  }
}
</style>

<style scoped>
.esign {
  max-width: 1000px;
  margin: 10px 0px;
}
.esigh-btns button {
  color: #fff;
  height: 40px;
  width: 100px;
  font-size: 16px;
  margin-right: 10px;
  outline: none;
  border-radius: 4px;
  background: #f60;
  border: 1px solid transparent;
}
.esigh-btns button:active {
  color: #fff;
  box-shadow: 0px 0px 50px orangered inset;
}
.esigh-result {
  margin-top: 10px 0px;
}
.esigh-result img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 1px solid #ececee;
}
.esignature {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}
span.error {
  color: #e74c3c;
}
</style>